import React from 'react';
import './loader.css'; 
import Loading from '../../images/Loader.png'

const Loader = () => {
  return (
    <div className="loader-container">
      <span>
        <img
          src={Loading}
          alt="Loading"
        />
      </span>
    </div>
  );
};

export default Loader;
