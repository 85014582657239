import React, { useState } from 'react';
import './largecalendar.css';
import { addDays, addMonths, subMonths, format } from 'date-fns';

const LargeCalendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);

    const daysInMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + 1,
        0
    ).getDate();

    const startOfMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        1
    );

    const startDay = startOfMonth.getDay();

    const handlePrevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
        setSelectedDate(null);
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
        setSelectedDate(null);
    };

    const handleDateClick = (day) => {
        setSelectedDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day));
    };

    const renderCalendar = () => {
        const calendar = [];

        for (let i = 0; i < startDay; i++) {
        calendar.push(<div key={`empty-${i}`} className="calendar-cell empty-cell"></div>);
        }

        for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        const isToday = format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
        const isSelected = selectedDate && format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd');

        calendar.push(
            <div
            key={day}
            className={`large-calendar-cell${isToday ? ' today' : ''}${isSelected ? ' selected-day' : ''}`}
            onClick={() => handleDateClick(day)}
            >
            {day}
            </div>
        );
        }

        return calendar;
    };

    // Function to generate an array of abbreviated days of the week
    const getDaysOfWeek = () => {
        const daysOfWeek = [];
        let currentDateCopy = new Date(currentDate); // Create a copy of the current date

        // Loop to generate days of the week
        for (let i = 0; i < 7; i++) {
        daysOfWeek.push(format(currentDateCopy, 'EEE')); // Format and push the abbreviated day (e.g., 'Sun', 'Mon')
        currentDateCopy = addDays(currentDateCopy, 1); // Move to the next day
        }

        return daysOfWeek;
    };

    return (
        <div className="large-calendar">
    
            <div className="large-calendar_container">
                <div className="large-calendar-header">
                    <button className="nav-button" onClick={handlePrevMonth}>
                    &lt;
                    </button>
                    
                    <div className="current-month">
                    {format(currentMonth, 'MMMM yyyy')}
                    </div>
                    <button className="nav-button" onClick={handleNextMonth}>
                    &gt;
                    </button>
                </div>
                <div className="days-of-week">
                    {getDaysOfWeek().map((day) => (
                    <div className="day-of-week" key={day}>
                        {day}
                    </div>
                    ))}
                </div>
                <div className="large-calendar-grid">
                    {renderCalendar()}
                </div>
            </div>
        </div>
    );
};

export default LargeCalendar;
