import React, { useState } from 'react'
import './payout.css'
import { BiMoney } from 'react-icons/bi'
import { Button } from '../../componentsIndex'
import { CgClose } from 'react-icons/cg'
import newAlert from '../../../utils/newAlert'

const Payout = ({open, closeModal} ) => {

    if (!open) return null

    const startPayout = async() => {
        // alert('Paying funds into account')
        newAlert("Paying funds into account.", "success")
        closeModal()
    }

    return (
        <>
            <div className="payout overlay" onClick={closeModal} />

                    
            <div className='payout modal'>
                <div className='payout_header'>
                    <button onClick={closeModal}><CgClose /></button>
                </div>

                <h2>£500.00</h2>
                <h3>Pay Funds into your Account</h3>

                <div className='payout_button'>
                    <Button
                    btnName="Start Payout"
                    handleClick={startPayout}
                    style={'button_filled'}
                    icon={<BiMoney />}
                    />
                </div>
                
                
            </div>

        
        </>
    )
}

export default Payout