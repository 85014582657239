import React, { useState } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import './calendar.css';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const importantDates = [
    new Date(2023, 8, 12),
    new Date(2023, 8, 24),
    new Date(2023, 9, 2),
    new Date(2023, 9, 15)
  ]; // Sample important dates
  // yyyy,m,dd   for some reason, it shows a month ahead

  const daysToShow = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - (3 - i));
    return {
      date,
      isImportant: importantDates.some(importantDate =>
        importantDate.toDateString() === date.toDateString()
      ),
    };
  });

  const handleArrowClick = (direction) => {
    const newDate = new Date(currentDate);
    if (direction === 'previous') {
      newDate.setDate(currentDate.getDate() - 7);
    } else {
      newDate.setDate(currentDate.getDate() + 7);
    }
    setCurrentDate(newDate);
  };

  return (
    <div className="calendar">
      <div className="calendar_header">
        <div className="calendar_arrow-left" onClick={() => handleArrowClick('previous')}>
          <IoIosArrowBack />
        </div>
        <div className="calendar_heading">{currentDate.toLocaleDateString('en', { month: 'long', year: 'numeric' })}</div>
        <div className="calendar_arrow-right" onClick={() => handleArrowClick('next')}>
          <IoIosArrowForward />
        </div>
      </div>
      <div className="calendar_content">
        {daysToShow.map((dayInfo, index) => (
          <div
            className={`calendar_content_day ${dayInfo.date.toDateString() === currentDate.toDateString() ? 'selected_day' : ''}`}
            key={index}
          >
            <div className="calendar_content_day_day">
              {dayInfo.date.toLocaleDateString('en', { weekday: 'short' }).substring(0, 1)}
            </div>
            <div
              className={`calendar_content_day_date ${
                dayInfo.date.toDateString() === currentDate.toDateString() ? 'selected_date' : ''
              } ${dayInfo.isImportant ? 'important_date' : ''} ${
                new Date().toDateString() === dayInfo.date.toDateString() ? 'current_date' : ''
              }`}
            >
              {dayInfo.date.getDate() < 10 ? `0${dayInfo.date.getDate()}` : dayInfo.date.getDate()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
