import React, { useEffect } from 'react'
import './preloader.css'
import Logo from '../../images/Loader.png'
import {preLoaderAnim} from '../../utils/preLoaderAnim'

const PreLoader = () => {

    useEffect(() => {
      preLoaderAnim()
    }, [])
    

    return (
        <div className='preloader'>
            
            <div className='preloader-content'>
                {/* <img
                    src={Logo}
                    alt="Description of the image"
                /> */}
                <span>connecting </span>
                <span>dreamers </span>
                <span>and </span>
                <span>doers</span>
            </div>
        </div>
    )
}

export default PreLoader