import React, { useEffect, useState } from "react";
import {AiOutlineSearch} from 'react-icons/ai'
import Style from "./searchbar.css";
import { useNavigate } from "react-router-dom";

const SearchBar = ({search_placeholder, query,  setQuery}) => {
  const navigate = useNavigate()

  // const submitSearch = () => {
  //   navigate(`/browse?search=${search}`)
  // }

  return (
    <div className='searchbar'>
      <div className='searchbar_box'>
        <input
          type="text"
          placeholder={search_placeholder}
          onChange={(e) => setQuery(e.target.value)}
        />

        <AiOutlineSearch 
          className='searchbar_box_icon' 
          // onClick={submitSearch}
        />  
      </div>
    </div>
  );
};

export default SearchBar;