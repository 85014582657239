import React, { useState } from 'react';
import './faqlist.css'

const FAQList = ({ data }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleQuestionClick = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    return (
        <div className='faq-container'>
        {data.map((item, index) => (
            <div key={index}>
            <div className='faq-q' onClick={() => handleQuestionClick(index)} >
                {item.question}
            </div>
            {openIndex === index && <div className='faq-a' >{item.answer}</div>}
            </div>
        ))}
        </div>
    );
};

export default FAQList;
