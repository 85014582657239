import React from 'react'
import './notification.css'
import { AiOutlineMail } from 'react-icons/ai'
import {AiOutlineMore} from 'react-icons/ai'


const Notification = () => {
  return (
    <div className='notification'>
        <div className='notification_profile'>

        </div>
        <div className='notification_content'>
            <div className='notification_content_header'>
                <AiOutlineMail className='notification_content_header_icon'/> 
                <h2>Dave Jacobs</h2>
                <p>4 hours ago</p>
            </div>
            <div className='notification_content_message'>You have received a message from Mark Jacobs about Graphic Design</div>
        </div>
        <div className='notification_action'>
        <AiOutlineMore />
        </div>
    </div>
  )
}

export default Notification