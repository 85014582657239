import React from 'react'
import './venture.css'
import { CgProfile } from 'react-icons/cg'
import { Button } from '../componentsIndex'

const Venture = ({venture, openOverlay}) => {

  return (
    <div className="venture-card">
      
      <div className="venture-header">
        <div className="venture-header_profile">
          <CgProfile />
        </div>
        <h3>{venture.role}</h3>
        <div className="venture-header_skill-bubbles">
          {venture.skills.map((skill, index) => (
            <div key={index} className="venture-header_skill-bubble">
              {skill}
            </div>
          ))}
        </div>
      </div>
      <div className="venture-description">
        <p>{venture.description}</p>
      </div>
      <div className="venture-info">
      <div className="venture-info_left">
        <p>{venture.applicants} people have applied so far</p>
        <h3>{venture.durationDays} days</h3>
      </div>
      <div className="venture-info_right">
        <p>Posted on {venture.datePosted}</p>
        <h3>N{venture.price}</h3>
      </div>
      </div>
            
      {/* open overlay with more info */}

      <div className="venture-button">
        <Button
          className="venture-button"
          btnName="Apply"
          handleClick={() => openOverlay(venture)}
          style={'button_filled'}
        />
      </div>
      
    </div>
  )
}

export default Venture