import React from 'react'
import './explainer.css'

const Explainer = ({left_title, left_content, right_title, right_content}) => {
  return (
    <section className='explainer_container_split'>

        <div className="explainer_container_split-section">
            <div className="explainer_title">
                {left_title}
            </div>
            <div className="explainer_content">
                {left_content}
            </div>
        </div>
    
        <div className="explainer_container_split-section">
        <div className="explainer_title">
                {right_title}
            </div>
            <div className="explainer_content">
                {right_content}
            </div>
        </div>

    </section>
    
  )
}

export default Explainer