import React, { useContext, useEffect, useState } from 'react'
import './project.css'
import {
    AiOutlineHeart, 
    AiFillHeart, 
    AiOutlineQrcode,
    AiOutlineStar,
    AiFillStar
} from 'react-icons/ai'
import {TbCurrencyNaira} from 'react-icons/tb'
import { Link, Navigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import newRequest from '../../utils/newRequest'
import { UserContext } from '../../context/UserContext';

const Project = ({props, icon} ) => {
    const {user} = useContext(UserContext)
    // console.log("🚀 ~ file: Project.jsx:18 ~ Project ~ user:", user)
    const [redirect, setRedirect] = useState('');


    props.type = 'static'
    const [isSaved, setIsSaved] = useState(user.savedProjects.includes(props._id));
    
    const handleSaveProject = async (ev, element) => {
        try {
            if (ev.target != element) {
                ev.stopPropagation()
            }
            if (isSaved) {
              await newRequest.post(`/user/${user._id}/save/${props._id}`);
              setIsSaved(false); // Toggle isSaved state when unsaving
            } else {
              await newRequest.post(`/user/${user._id}/save/${props._id}`);
              setIsSaved(true); // Toggle isSaved state when saving
            }
        } catch (error) {
            console.error('Error toggling project save:', error);
            alert('Error toggling project save.');
        }
    };

    const openProject = async (ev, element) => {
        try {
            // if (ev.target != element) {
                // ev.stopPropagation()
                setRedirect(true)
            // }
            
        } catch (error) {
            console.error('Error opening project:', error);
        }
    };
    


    
    if (redirect) {
        return <Navigate to={`/project/${props._id}`} target={"_blank"} />
    }
    return (
        <div className='project' onClick={ev => openProject(ev, this)}>
            {
                props.type == 'static' ?
                    <div className="project_card">
                        {icon && (
                            <Link to={`/user/profile/${props.owner.username}`}>
                            <div className="project_card-header">
                                <div className="project_card-header_user">
                                    <img src={"https://cdn2.benzinga.com/files/imagecache/og_image_social_share_1200x630/images/story/2012/d.jpg?width=720"}/>
                                    {props.owner.firstName} {props.owner.lastName}
                                    <span>@{props.owner.username}</span>
                                </div>
                            </div>
                        </Link>
                        )}
                        

                        {/* <Link to={`/project/${props._id}`}> */}
                            <div className="project_card-content">
                                <div className="project_card-content_container">
                                    <div className="project_card_badge">
                                        <span>STAR SELLER</span>
                                    </div>

                                    <div className="project_card-content_action">
                                        <span onClick={ev => handleSaveProject(ev, this)}>{props.likes}{isSaved ? <AiFillHeart /> : <AiOutlineHeart />} </span>
                                    </div>
                                </div>
                                <img src={props.coverImg ? "http://localhost:4000" + props.coverImg : "https://i.pinimg.com/originals/4a/e9/e5/4ae9e55b5b2d8730017cb33615b56609.jpg"}/>
                                <div className="project_card-content_qr-code">
                                    <span>/INFO</span>
                                    <AiOutlineQrcode />
                                </div>
                            </div>
                        {/* </Link> */}
                        <div className="project_card-footer">
                            <div className="project_card-footer_price">
                                <span>STARTING FROM</span>
                                <div><TbCurrencyNaira /> {props.price[0]}</div>
                            </div>
                            <div className="project_card-footer_description">
                            <span>{props.title}</span>
                            <span>
                                { !isNaN(props.totalRatings / props.stars) &&
                                    Math.round(props.totalRatings / props.stars)
                                }
                            </span>
                            </div>
                        </div>
                    </div>
                :props.type == 'audio' ?
                    <div className="project_card project_card-audio">
                        {icon && (
                            <Link to={`/user/profile/${props.username}`}>
                                <div className="project_card-header">
                                    <div className="project_card-header_user">
                                    <img src="https://m.media-amazon.com/images/I/51KUrVgVH-L._SL1000_.jpg"/>
                                    {props.firstName} {props.lastName}
                                    <span>@{props.username}</span>
                                    </div>
                                </div>
                            </Link>
                        )}
                        <Link to={`/projects/${props._id}`}>
                            <div className="project_card-content">
                                <div className='wave'></div>
                                <div className='wave'></div>
                                <div className='wave'></div>
                                
                                <div className="project_card-content_container">
                                    <div className="project_card_badge">
                                        <span>STAR SELLER</span>
                                    </div>

                                    <div className="project_card-content_action">
                                        <span>{props.likes} <AiOutlineHeart /></span>
                                    </div>
                                </div>

                                <img src="https://m.media-amazon.com/images/I/51KUrVgVH-L._SL1000_.jpg"/>
                                <div className="project_card-content_qr-code">
                                    <span>/INFO</span>
                                    <AiOutlineQrcode />
                                </div>
                            </div>
                        </Link>
                        <div className="project_card-footer">
                        <div className="project_card-footer_price">
                                <span>STARTING FROM</span>
                                <div><TbCurrencyNaira /> {props.price[0]}</div>
                            </div>
                            <div className="project_card-footer_description">
                            <span>{props.title}</span>
                            </div>
                        </div>
                    </div>
                :props.type == 'video' ?
                    <div className="project_card project_card-video">
                        {icon && (
                            <Link to={`/user/profile/${props.username}`}>
                                <div className="project_card-header">
                                    <div className="project_card-header_user">
                                        <img src="https://cdn2.benzinga.com/files/imagecache/og_image_social_share_1200x630/images/story/2012/d.jpg?width=720"/>
                                        {props.firstName} {props.lastName}
                                        <span>@{props.username}</span>
                                    </div>
                                </div>
                            </Link>
                        )}
                        
                        <Link to={`/projects/${props.title}`}>
                            <div className="project_card-content">
                                <div className="project_card-content_container">
                                    <div className="project_card_badge">
                                        <span>STAR SELLER</span>
                                    </div>

                                    <div className="project_card-content_action">
                                        <span>{props.likes} <AiOutlineHeart /></span>
                                    </div>
                                </div>
                                <video autoplay playsinline muted loop preload poster="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/oceanshot.jpg">
                                    <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/ocean-small.webm" />
                                    <source src="http://thenewcode.com/assets/videos/ocean-small.mp4" />
                                </video>
                                <div className="project_card-content_qr-code">
                                <span>/INFO</span>
                                <AiOutlineQrcode />
                                </div>
                            </div>
                        </Link>
                        <div className="project_card-footer">
                            <div className="project_card-footer_price">
                                <span>STARTING FROM</span>
                                <div><TbCurrencyNaira /> {props.price[0]}</div>
                            </div>
                            <div className="project_card-footer_description">
                                <span>{props.title}</span>
                            </div>
                        </div>
                    </div>
                :props.type == 'new' ?
                    <div className="project_card project_card-add-project">
                        <div className="project_card-header"></div>
                        
                        <div className="project_card-content">
                        
                            <div className="project_card-content_description">
                            <IoMdAdd />
                            </div>
                            
                        </div>
                    </div>    
                
                :props.type == 'static'
            }


            


            


            {/* <div className="project_card project_card-category">
                <div className="project_card-content">
                
                    <div className="project_card-content_title">
                    <h3><span>Category</span> Gaming</h3>
                    </div>
                    
                    <img src="https://lh3.googleusercontent.com/0uehawLNRzOUSs4qtxX0o5cDenklvm0APLUfJng0kwOu-CCX_hZZI39kf8lbfMKrOB0D0VfXJqKcyMnrUnnBW5s5Gifz6GcCt12j_Q=w1400-k"/>
                    <img src="https://assets.polkamon.com/images/Unimons_T11C03H01B07G00.jpg"/>
                    <img src="https://lh3.googleusercontent.com/geTFW9hgPMsAiifYUNutYZTXfREGWJ39PJeCRC3DIFgX874pnCY9Y_gKHKQwUOM_HIbE2Wf5KFqgoPiJmr_mtczCLj9ExGlmQ5ZR"/>
                    <img src="https://res.cloudinary.com/nftrade/image/upload/w_500,c_scale/v1633826885/evm_56_0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d_10002421270.jpg"/>
                
                    
                </div>
                <div className="project_card-footer">
                    <div className="project_card-footer_description">
                    <span><i className="far fa-star"></i> Curated Collection</span>
                    </div>
                    
                </div>
            </div>


            <div className="project_card project_card-add-project">
            <div className="project_card-header"></div>
            
            <div className="project_card-content">
            
                <div className="project_card-content_description">
                <i className="fas fa-plus-square"></i>
                <h3>Upload <span>Drag & Drop</span></h3>
                <input type="button" className="btn btn--secondary" value="Browse Device" />
                </div>
                
            </div>
            
            <div className="project_card-footer"></div>

            </div>


            <div className="user-profile ">

                <div className="user-profile-content">
                    <div className="project_card-header_user">
                        <img src="https://s2.coinmarketcap.com/static/img/coins/200x200/8968.png"/>
                    </div>
                    
                    <img src="https://miro.medium.com/max/960/0*9GkThfRr7h_iXyPr.gif"/>
                    
                    <div className="user-profile-content_user-info">
                        <h3>Polychain Monsters
                            <span>@polychainmonsteers</span>
                        </h3>
                    </div>
                    
                    <div className="project_card-content_action">
                        <input type="button" className="btn btn--icon" value="√"/>
                        <input type="button" className="btn btn--primary" value="subscribe"/>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Project