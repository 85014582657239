import React, { useEffect, useState } from 'react';
import './profileform.css'
import {Button} from '../../../components/componentsIndex'

const ProfileForm = () => {
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const totalSteps = 3;
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repassword: '',
    category: '',
    jobStatus: '',
  });

  const [progress, setProgress] = useState((page / totalSteps) * 100);
  
  const nextPage = () => {
    if (validatePage()) {
      setPage((prevPage) => prevPage + 1);
      // updateProgress()
      setError('');
    } else {
      setError('Please complete the required fields.');
    }
  };
  
  const previousPage = () => {
    setPage((prevPage) => prevPage - 1);
    // updateProgress()
    console.log('category: ',formData.category)

  };
  
  const updateProgress = () => {
    setProgress((prevProgress) => (page / totalSteps) * 100);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePage = () => {
    switch (page) {
      case 1:
        return formData.username !== '' && formData.firstName !== '' && formData.lastName !== '';
      case 2:
        return formData.password !== '' && formData.repassword !== '';
      case 3:
        return formData.password !== '' && formData.jobStatus !== '';
      default:
        return true;
    }
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePage()) {
      setError('')

      // Send formData to create user (based on your API)
    } else {
      // setError('Please complete the required fields.')

    }
  };

  const renderPage = () => {
    switch (page) {
      case 1:
        return (
          <div className='profile-form_input'>
            <h2>Welcome</h2>
            <p>What is your first name?</p>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
            <p>What is your last name?</p>

            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
            />
            <p>Create a username you will go by.</p>

            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
            />
          </div>
        );
      case 2:
        return (
          <div className='profile-form_input'>
            <h2>Secure your Account</h2>
            <p>Create a unique password you will remember.</p>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              
            />
            <p>Confirm your password.</p>

            <input
              type="password"
              name="repassword"
              value={formData.repassword}
              onChange={handleChange}
              placeholder="Re-Type Password"
            />
          </div>
        );
      case 3:
        return (
          <div className='profile-form_input'>
            <h2>About You</h2>
            <p>What is your primary area of expertise?</p>
            <select value={formData.category} onChange={handleChange}>
              <optgroup label="Graphic Design">
                <option value="volvo">Logo Design</option>
                <option value="volvo">Website Design</option>
                <option value="volvo">Cover Design</option>
              </optgroup>

              <option value="saab">Data Entry</option>

              <optgroup label="Writing & Translation">
                <option value="volvo">CopyWriting</option>
                <option value="volvo">Ghost Writing</option>
              </optgroup>

              <optgroup label="Software Development">
                <option value="volvo">Website Development</option>
                <option value="volvo">Application Development</option>
                <option value="volvo">SEO</option>
                <option value="volvo">Bug Fixes</option>
              </optgroup>


              <option value="audi">Social Media</option>
              <option value="audi">Video & Animation</option>
              <option value="audi">Internet Technology</option>
              <option value="audi">Music & Audio</option>
              <option value="audi">Photography</option>
              <option value="audi">AI</option>
              <option value="audi">Lifestyle</option>
            </select>

            <p>What is your job status?</p>
            <input onChange={handleChange} type="checkbox" name='jobStatus' /><label for="jobStatus">Employed</label>
            <input onChange={handleChange} type="checkbox" name='jobStatus' /><label for="jobStatus">Unemployed</label>
            <input onChange={handleChange} type="checkbox" name='jobStatus' /><label for="jobStatus">Student</label>
          </div>
        );
      case 4:
        return (
          <div className='profile-form_input'>
            <h2>Step 3: Password</h2>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
            />
          </div>
        );
      default:
        return null;
    }
  };


  useEffect(() => {
    updateProgress()
  
  
  }, [page])
  

  return (
    
    <div className='profile-form'>
      <div className='profile-form overlay' >
        <div className='profile-form modal'>
          <div className="profile-form_progress">
            <div className="profile-form_progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
          <form onSubmit={handleSubmit}>
            
            <div className="profile-form_form">
              {renderPage()}
              {error && (<div className="err-message">{error}</div>)}

            </div>
            <div className='profile-form_buttons'>
              {page !== 1 && 
              
                <Button
                  className='profile-form_button'
                  btnName={'Previous'}
                  handleClick={previousPage}
                  style={'button_outline'}
                />
              }
              {
                page <= totalSteps && 
                  <Button
                    btnName={page === totalSteps ? 'Submit' : 'Next'}
                    handleClick={nextPage}
                    style={'button_outline'}
                  />
              }

              
            </div>
            

            
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
