import React, { useState } from 'react';
import './expandingcards.css';

const ExpandingCards = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    // Function to handle click events on cards
    const handleClick = index => {
        setActiveIndex(index);
    }

    // Array of card details, including background URLs and icons
    const cardDetails = [
        { 
            title: 'Make Account',
            content: "Crafting your future begins with a simple step - making an account. Save your spot in the waitlist and get notified when it is time.",
        },
        { 
            title: 'Create Portfolio',
            content: "No more hurdles, just highlights. Creating your portfolio is a breeze. Let the world see your skills at a glance.",
        },
        { 
            title: 'Start Selling',
            content: "Turning passion into profit has never been this straightforward. Dive into the marketplace and let your skills shine. Your success story begins with a single click.",
        },
        { 
            title: 'Get Paid',
            content: "Payment made easy. Receive your hard-earned pay with effortless withdrawals and secure transactions. Your success, your way.",
        },
    ];

    return (
        <div className="expanding-cards_options">
            {cardDetails.map((card, index) => (
                <div
                    key={index}
                    className={activeIndex === index ? 'expanding-cards_options_active' : 'expanding-cards_options_option'}
                    onClick={() => handleClick(index)}
                    // style={{ '--optionBackground': `url(${card.bgUrl})` }}
                >
                    {/* <div className="shadow"></div> */}
                    <div className="expanding-cards_options_option_label">
                        <div className="expanding-cards_options_option_label_index">
                            <>0{index + 1}.</>
                            <div className="main">{card.title}</div>

                        </div>
                        <div className="expanding-cards_options_option_label_info">
                            <div className="expanding-cards_options_option_label_info_content">{card.content}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ExpandingCards;
