import React from 'react'
import './addproject.css'
import { IoMdAdd } from 'react-icons/io'


const AddProject = () => {
  return (
    <div className="add-project add-project-new-project">
      <div className="add-project-header"></div>
      
      <div className="add-project-content">
      
          <div className="add-project-content_description">
          <IoMdAdd />
          </div>
          
      </div>
    </div>    
  )
}

export default AddProject