import axios from "axios"
import { createContext, useEffect, useState } from "react"
import newRequest from "../utils/newRequest"
import { Loader } from "../components/componentsIndex"

export const UserContext = createContext()

const UserContextProvider = ({children}) => {
  const [user, setUser] = useState({})
  const [loggedIn, setLoggedIn] = useState(undefined)
  const [loading, setLoading] = useState(true)

  // const [ready, setReady] = useState(false)

  const getLoggedIn = async () => {
    try {
      const loggedInResponse = await newRequest.get("/auth/loggedIn");
      setLoggedIn(loggedInResponse.data);
    } catch (error) {
      // Handle error, e.g., show an error message'
      console.log(error)
    } finally {
      setLoading(false); // Set loading to false after the request is done
    }
  }

  const getUser = async () => {
    try {
      const userResponse = await newRequest.get("/auth/userProfile");
      setUser(userResponse.data);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.log(error)
    }
  }

  useEffect(()=> {
    getLoggedIn()
    getUser()
  }, [])

  if (loading) {
    // Show a loading indicator while data is being fetched
    return <Loader />;
  }

  return ( 
    // , ready
    <UserContext.Provider value={{loggedIn, getLoggedIn, user, getUser}}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
