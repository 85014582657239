import React from 'react'
import { Link } from 'react-router-dom';
import './button.css'

const Button = ({btnName, handleClick, icon, style, link, type, selected}) => {
  return (
    <Link to={link}>

        <button
        className={`button ${selected ? 'selected' : ''} ${style}`}
          onClick={() => handleClick()}
          type={type}
        >
          {icon} <span className='button_text'>{btnName}</span>
        </button>

    </Link>
  )
}

export default Button