import React, { useState } from 'react'
import './requirementsform.css'

const RequirementsForm = ({requirements, answers, setAnswers}) => {

  
  const [error, setError] = useState(null)

  return (
    <div className='req-form'>
      <h2>Requirement Questions</h2>
      {requirements && requirements.map((requirement, index) => (
        <div className='req-form_question' key={index}>

          <div className='req-form_question_question'>
            <h3>{index + 1}.</h3>
            <p>{requirement.question}</p>

          </div>
          <div className='req-form_question_options'>
            {/* <p>Type: {requirement.type}</p> */}
            {requirement.type === 'dropdown' && (
              <>
                {/* <label htmlFor="mySelect">Select an option:</label> */}
                <select 
                  id="mySelect" 
                  value={answers[requirement._id] || ""}
                  onChange={(e) =>
                    setAnswers((prevAnswers) => ({
                      ...prevAnswers,
                      [requirement._id]: e.target.value,
                    }))
                  }
                >
                  <option value="">Select an option...</option>
                  {requirement.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </>

            )}

            {requirement.type === 'text' && (
              <>
                {/* <label htmlFor="mySelect">Select an option:</label> */}
                <input 
                  type="text" 
                  value={answers[requirement._id] || ""} 
                  onChange={(e) =>
                    setAnswers((prevAnswers) => ({
                      ...prevAnswers,
                      [requirement._id]: e.target.value,
                    }))
                  }
                />
              </>

            )}

            {requirement.type === 'image' && (
              <>
                {/* <label htmlFor="mySelect">Select an option:</label> */}
                {/* <input type="file" name="" id="" /> */}
                <input 
                  type="text" 
                  value={answers[requirement._id] || ""}
                  onChange={(e) =>
                    setAnswers((prevAnswers) => ({
                      ...prevAnswers,
                      [requirement._id]: e.target.value,
                    }))
                  }
                />
              </>

            )}
          </div>
          


          
        </div>
      ))}
    </div>
  )
}

export default RequirementsForm