import React, { useEffect, useState } from 'react'
import newRequest from '../../utils/newRequest';
import { useParams } from 'react-router-dom';

const VerificationPage = () => {
  const {token} = useParams()

  const [verified, setVerified] = useState(false);

  


  const verifyEmail = async () => {
    try {
      await newRequest.get(`/waiting-list/verify/${token}`).then(response => {
        setVerified(true)
        console.log(response.data)
      })
    } catch (error) {
      console.error('Could not verify user', error);
    }
  }

  useEffect(() => {
    verifyEmail()
  }, []);

  if (!token) {
    return <>No token to verify (change to 404)</>
  }
  

  

  return (
    <div>
      <h2>
        {verified && 
          (
            <>Your email has been verified</>
          )
        }
        {verified === false && 
          (
            <>Error validating user</>
          )
        }
          
      </h2>
    </div>
  )
}

export default VerificationPage