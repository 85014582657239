import React from 'react'
import './footer.css'

import { Link, Navigate, useParams } from 'react-router-dom'



const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer_top'>
        <div className="footer_top_category">
          <h2>Categories</h2>
          
          <div><Link to={'/browse?search=User 1'}>User 1</Link></div>
          <div><Link to={'/browse?search=User 2'}>User 2</Link></div>
          <div><Link to={'/browse?search=User 3'}>User 3</Link></div>
          <div><Link to={'/browse?search=Graphics & Design'}>Graphics & Design</Link></div>
          <div><Link to={'/browse?search=Digital Marketing'}>Digital Marketing</Link></div>
          <div><Link to={'/browse?search=Writing & Translation'}>Writing & Translation</Link></div>
          <div><Link to={'/browse?search=Video & Animation'}>Video & Animation</Link></div>
          <div><Link to={'/browse?search=Music & Audio'}>Music & Audio</Link></div>
          <div><Link to={'/browse?search=Programming & Tech'}>Programming & Tech</Link></div>
          <div><Link to={'/browse?search=Data'}>Data</Link></div>
          <div><Link to={'/browse?search=Business'}>Business</Link></div>
          <div><Link to={'/browse?search=Lifestyle'}>Lifestyle</Link></div>
          <div><Link to={'/browse?search=Photography'}>Photography</Link></div>
          <div><Link to={'/browse?search=End-to-End Projects'}>End-to-End Projects</Link></div>
          <div><Link to={'/browse?search=Sitemap'}>Sitemap</Link></div>
        </div>
        <div className="footer_top_category">
          <h2>About</h2>

          <div><Link to={'/browse?search=Graphics & Design'}>Graphics & Design</Link></div>
          <div><Link to={'/browse?search=Digital Marketing'}>Digital Marketing</Link></div>
          <div><Link to={'/browse?search=Writing & Translation'}>Writing & Translation</Link></div>
          <div><Link to={'/browse?search=Video & Animation'}>Video & Animation</Link></div>
          <div><Link to={'/browse?search=Music & Audio'}>Music & Audio</Link></div>
          <div><Link to={'/browse?search=Programming & Tech'}>Programming & Tech</Link></div>
          <div><Link to={'/browse?search=Data'}>Data</Link></div>
          <div><Link to={'/browse?search=Business'}>Business</Link></div>
          <div><Link to={'/browse?search=Lifestyle'}>Lifestyle</Link></div>
          <div><Link to={'/browse?search=Photography'}>Photography</Link></div>
          <div><Link to={'/browse?search=End-to-End Projects'}>End-to-End Projects</Link></div>
          <div><Link to={'/browse?search=Sitemap'}>Sitemap</Link></div>
        </div>
        <div className="footer_top_category">
          <h2>Support</h2>

          <div><Link to={'/browse?search=Graphics & Design'}>Graphics & Design</Link></div>
          <div><Link to={'/browse?search=Digital Marketing'}>Digital Marketing</Link></div>
          <div><Link to={'/browse?search=Writing & Translation'}>Writing & Translation</Link></div>
          <div><Link to={'/browse?search=Video & Animation'}>Video & Animation</Link></div>
          <div><Link to={'/browse?search=Music & Audio'}>Music & Audio</Link></div>
          <div><Link to={'/browse?search=Programming & Tech'}>Programming & Tech</Link></div>
          <div><Link to={'/browse?search=Data'}>Data</Link></div>
          <div><Link to={'/browse?search=Business'}>Business</Link></div>
          <div><Link to={'/browse?search=Lifestyle'}>Lifestyle</Link></div>
          <div><Link to={'/browse?search=Photography'}>Photography</Link></div>
          <div><Link to={'/browse?search=End-to-End Projects'}>End-to-End Projects</Link></div>
          <div><Link to={'/browse?search=Sitemap'}>Sitemap</Link></div>
        </div>
        <div className="footer_top_category">
          <h2>Community</h2>

          <div><Link to={'/browse?search=Graphics & Design'}>Graphics & Design</Link></div>
          <div><Link to={'/browse?search=Digital Marketing'}>Digital Marketing</Link></div>
          <div><Link to={'/browse?search=Writing & Translation'}>Writing & Translation</Link></div>
          <div><Link to={'/browse?search=Video & Animation'}>Video & Animation</Link></div>
          <div><Link to={'/browse?search=Music & Audio'}>Music & Audio</Link></div>
          <div><Link to={'/browse?search=Programming & Tech'}>Programming & Tech</Link></div>
          <div><Link to={'/browse?search=Data'}>Data</Link></div>
          <div><Link to={'/browse?search=Business'}>Business</Link></div>
          <div><Link to={'/browse?search=Lifestyle'}>Lifestyle</Link></div>
          <div><Link to={'/browse?search=Photography'}>Photography</Link></div>
          <div><Link to={'/browse?search=End-to-End Projects'}>End-to-End Projects</Link></div>
          <div><Link to={'/browse?search=Sitemap'}>Sitemap</Link></div>
        </div>
        <div className="footer_top_category">
          <h2>More Reeflex</h2>
          
          <div><Link to={'/browse?search=Graphics & Design'}>Graphics & Design</Link></div>
          <div><Link to={'/browse?search=Digital Marketing'}>Digital Marketing</Link></div>
          <div><Link to={'/browse?search=Writing & Translation'}>Writing & Translation</Link></div>
          <div><Link to={'/browse?search=Video & Animation'}>Video & Animation</Link></div>
          <div><Link to={'/browse?search=Music & Audio'}>Music & Audio</Link></div>
          <div><Link to={'/browse?search=Programming & Tech'}>Programming & Tech</Link></div>
          <div><Link to={'/browse?search=Data'}>Data</Link></div>
          <div><Link to={'/browse?search=Business'}>Business</Link></div>
          <div><Link to={'/browse?search=Lifestyle'}>Lifestyle</Link></div>
          <div><Link to={'/browse?search=Photography'}>Photography</Link></div>
          <div><Link to={'/browse?search=End-to-End Projects'}>End-to-End Projects</Link></div>
          <div><Link to={'/browse?search=Sitemap'}>Sitemap</Link></div>
        </div>
      
      </div>
      <div className='footer_bottom'>

        <div className='footer_bottom_left'>
          <h2>reeflex</h2>
          <span>Reeflex Nigeria Ltd</span>
        </div>
        <div className='footer_bottom_right'>
          social icons
        </div>
      </div>
    </div>
  )
}

export default Footer