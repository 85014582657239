import React, { useEffect, useState } from 'react'
import './text.css'
import newRequest from '../../utils/newRequest'
import TimeAgo from 'timeago-react';


const Text = ({text, currentUser}) => {

    const [user, setUser] = useState(null)
    
    const getUser = async() => {

        
        try {
          const {data} = await newRequest.get(`/user/${text.userId}`)
          setUser(data)
        } catch (err) {
        //   setError(err.response.data)
          console.log("🚀 ~ file: HomeSection.jsx:53 ~ getProjects ~ err.response.data:", err.response.data)
        }
      }
    
      useEffect(() => {
        getUser()
      }, [text])
  return (
    <div className={currentUser ? 'text ownText' : 'text'}>
        <div className='text_sender'>
            <img
                src='https://placekitten.com/30/30' // Replace with the sender's profile picture URL
                alt='Sender'
                className='text_sender-picture'
            />
            <h4>{currentUser ? "Me" : <>{user?.firstName} {user?.lastName}</>}</h4>
            <p><TimeAgo datetime={text.createdAt} /></p>
        </div>
        <p className='text_message'>{text.message}</p>
    </div>
  )
}

export default Text