import React from 'react'
import "./orderprogressbar.css";
import PropTypes from "prop-types";
import { CgCheck } from 'react-icons/cg';

const OrderProgressBar = ({ activeStep }) => {

  const steps = ["Confirm Order", "Payment", "Requirements", "In Progress", "Review", "Finish"];

  return (
    <div className="order-progress-bar">
      {steps.map((step, index) => (
        <div key={index} className={`order-progress-bar_step ${index < activeStep + 2 ? "active-step" : ""}`}>

            <span 
              className="order-progress-bar_label ">{step}
            </span>
            
        </div>
      ))}
    </div>
  )
}

// OrderProgressBar.propTypes = {
//     activeStep: PropTypes.number.isRequired,
//   };

export default OrderProgressBar