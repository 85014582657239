import React, { useEffect, useState } from 'react'
import './invoice.css'
import { Button } from '../../componentsIndex'
import { CgClose } from 'react-icons/cg'
import newAlert from '../../../utils/newAlert'
import newRequest from '../../../utils/newRequest'

const Invoice = ({open, closeModal, invoiceId} ) => {

    const [invoice, setInvoice] = useState([])
    const [error, setError] = useState(null)

    const openInvoice = async() => {
        // alert('Paying funds into account')
        newAlert("Redirecting to Invoice", "success")
        closeModal()
    }

    const getInvoice = async() => {
        try {
          const {data} = await newRequest.get(`/invoices/invoice/${invoiceId}`)
          setInvoice(data)
        } catch (err) {
          setError(err.response.data)
          console.log("🚀 ~ file: Invoice.jsx:25 ~ getInvoice ~ err.response.data:", err.response.data)
        }
    } 
  
  
    useEffect(() => {
      getInvoice()
    }, [])
    console.log("🚀 ~ file: Invoice.jsx:11 ~ Invoice ~ invoice:", invoice)


    if (!open) return null

    return (
        <>
            <div className="invoice overlay" onClick={closeModal} />

                    
            <div className='invoice modal'>
                <div className='invoice_header'>
                    <button onClick={closeModal}><CgClose /></button>
                </div>

                <h2>Invoice for {invoice.buyerFirstName && invoice.buyerFirstName} {invoice.buyerLastName && invoice.buyerLastName}</h2>
                <div className="invoice_title">
                    <div><strong>Invoice Number:</strong> {invoice.invoiceNumber}</div>
                    <div><strong>Invoice Issued:</strong> {invoice.issueDate}</div>
                </div>
                <div className="invoice_buyer">
                    <h2>Client Details</h2>
                    <div><strong>User:</strong> {invoice.buyerFirstName && invoice.buyerFirstName} {invoice.buyerLastName && invoice.buyerLastName} ({invoice.buyerUsername && invoice.buyerUsername})</div>
                </div>
                <div className="invoice_freeelancer">
                    <h2>Freelancer Details</h2>
                    <div><strong>User:</strong> {invoice.freelancerFirstName && invoice.freelancerFirstName} {invoice.freelancerLastName && invoice.freelancerLastName} ({invoice.freelancerUsername && invoice.freelancerUsername})</div>
                </div>
                <div className="invoice_project">
                    <div><strong>Project:</strong> {invoice.title && invoice.title}</div>
                    <div><strong>Category:</strong> {invoice.category && invoice.category}</div>
                    <div><strong>Chosen Package:</strong> {invoice.package && (invoice.package === 0 ? "Basic": invoice.package === 1 ? "Standard":  "Premium")}</div>
                    
                </div>
                <div className="invoice_total">

                    <strong>Status:</strong> {invoice.status && invoice.status}
                    <strong>Total:</strong> {invoice.totalAmount && invoice.totalAmount}
                </div>


                {/* get invoice from api, then show invoice details */}

                <div className='invoice_button'>
                    <Button
                        btnName="View full Invoice"
                        handleClick={openInvoice}
                        style={'button_filled'}
                    />
                </div>
                
                
            </div>

        
        </>
    )
}

export default Invoice