import './App.css';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import UserContextProvider, { UserContext } from './context/UserContext';
import { Suspense, lazy, useContext, useEffect, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { Loader } from './components/componentsIndex';
import VerificationPage from './pages/VerificationPage/VerificationPage';

import RequireAuth from './RequireAuth/RequireAuth';

// Lazy load page routes
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'))
const WaitingListPage = lazy(() => import('./pages/WaitingListPage/WaitingListPage'))
const JoinPage = lazy(() => import('./pages/JoinPage/JoinPage'))
const ProjectDetailsPage = lazy(() => import('./pages/ProjectDetailsPage/ProjectDetailsPage'))
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'))
const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'))
const MarketplacePage = lazy(() => import('./pages/MarketplacePage/MarketplacePage'))
const Layout = lazy(() => import('./Layout/Layout'))
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const SubscriptionPage = lazy(() => import('./pages/SubscriptionPage/SubscriptionPage'))
const RegisterPage = lazy(() => import('./pages/RegisterPage/RegisterPage'))
const CreateVenturePage = lazy(() => import('./pages/CreateVenturePage/CreateVenturePage'))
const ComingSoonPage = lazy(() => import('./pages/ComingSoonPage/ComingSoonPage'))
const FlexInstantPage = lazy(() => import('./pages/FlexInstantPage/FlexInstantPage'))
const InboxPage = lazy(() => import('./pages/InboxPage/InboxPage'))
const FreelancerReview = lazy(() => import('./pages/ReviewPage/FreelancerReview'))
const BuyerReview = lazy(() => import('./pages/ReviewPage/BuyerReview'))
const SuccessPage = lazy(() => import('./pages/SuccessPage/SuccessPage'))
const PaymentPage = lazy(() => import('./pages/PaymentPage/PaymentPage'))
const RequirementsPage = lazy(() => import('./pages/RequirementsPage/RequirementsPage'))
const AdminDashboardPage = lazy(() => import('./pages/AdminDashboardPage/AdminDashboardPage'))
const SearchPage = lazy(() => import('./pages/SearchPage/SearchPage'))
const AboutPage = lazy(() => import('./pages/aboutPage/AboutPage'))
const UnauthorizedPage = lazy(() => import('./pages/UnauthorizedPage/UnauthorizedPage'))

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true


function App() {
  const queryClient = new QueryClient()
  // console.log("🚀 ~ file: App.js:41 ~ App ~ user:", user)
  


  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <UserContextProvider>
          <Routes>
            <Route path='/' element={<Layout />}>

              {/* PUBLIC */}
              {/* main */}
              <Route index element={<LandingPage />} />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              <Route path="/waiting-list" element={<WaitingListPage />} />
              <Route path="/join" element={<JoinPage />} />
              <Route path="/verify/:token" element={<VerificationPage />} />

              {/* PRIVATE */}
              <Route element={<RequireAuth />}>
                <Route path="/dashboard/:subpage?" element={<DashboardPage />} />
                {/* <Route 
                  path="/dashboard/" 
                  element={<DashboardPage />} 
                  children=[]
                /> */}

                {/* <Route path="/dashboard/:subpage" element={<DashboardPage />} /> */}
                {/* <Route path="/dashboard/:subpage/:action" element={<DashboardPage />} /> */}
                <Route path="/dashboard/:subpage/:action?/:id?" element={<DashboardPage />} />

                
                {/* admin */}
                <Route path="/admin/:subpage?" element={<AdminDashboardPage />} />
                <Route path="/admin/:subpage/:action" element={<AdminDashboardPage />} />
                
                
                {/* payment */}
                <Route path="/payment/:id/:packageNo" element={<PaymentPage />} />
                <Route path="/success/:id?" element={<SuccessPage />} />
                <Route path="/requirements/:id" element={<RequirementsPage />} />

                
                {/* review */}
                <Route path="/breview" element={<BuyerReview />} />
                <Route path="/freview" element={<FreelancerReview />} />

                {/* inbox */}
                <Route path="/inbox/:conversation?" element={<InboxPage />} />

                {/* Temporarily Inaccesible */}
                {/* auth */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />

                {/* project */}
                <Route path="/project/:id" element={<ProjectDetailsPage />} />

                {/* user */}
                <Route path="/profile/:id" element={<ProfilePage />} />
                
                <Route path="/flex-instant" element={<ComingSoonPage />} />
                <Route path="/marketplace" element={<MarketplacePage />} />
                <Route path="/marketplace/:subpage" element={<CreateVenturePage />} />
                <Route path="/about" element={<AboutPage />} />

                <Route path="/browse" element={<SearchPage />} />
              </Route>
            </Route>
          </Routes>
        </UserContextProvider>
      </Suspense>
    </QueryClientProvider>

  );
}

export default App;
