import React, { useEffect, useState } from 'react'
import './message.css'
import {CgProfile} from 'react-icons/cg'
import newRequest from '../../utils/newRequest'
import TimeAgo from 'timeago-react'

const Message = ({conversation, currentUser}) => {

    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)


    const getUser = async() => {

        const contactId = conversation.members.find((member) => member !== currentUser._id) // get user that is not current user
        try {
        const {data} = await newRequest.get(`/user/${contactId}`)
        setUser(data)
        } catch (err) {
        setError(err.response.data)
        console.log("🚀 ~ file: HomeSection.jsx:53 ~ getProjects ~ err.response.data:", err.response.data)
        }
    }

    useEffect(() => {
        getUser()
    }, [currentUser, conversation])
    // console.log("🚀 ~ file: Message.jsx:27 ~ Message ~ conversation:", conversation)

  return (
    <div className='message'>
        
        <div className="message_left">
            <div className='message_icon'>
                <CgProfile />
            </div>
            <div className='message_details'>
                <div className='message_details_from'>
                <strong>{user && user.firstName} {user && user.lastName}</strong>
                </div>
                <div className='message_details_message'>
                    {conversation.lastMessage.text}
                </div>
            </div>
        </div>
        <div className="message_right">
            <div className='message_time'>
               <p><TimeAgo datetime={conversation.updatedAt} /></p> 
            </div>
        </div>
        
        
    </div>
  )
}

export default Message