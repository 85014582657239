import React, { useEffect, useState } from 'react'
import newRequest from '../../utils/newRequest'
import './review.css'
import { AiFillStar } from 'react-icons/ai'

const Review = ({review}) => {
// console.log("🚀 ~ file: Review.jsx:6 ~ Review ~ review:", review)

  const [user, setUser] = useState([])
  useEffect(() => {
    newRequest.get(`/user/${review.userId}`).then(response => {
      setUser(response.data)
    })
  }, [])

    

  return (
    <div className='review'>
      <div className="reviewer_name">
        username: {user.username}
      </div>
      <div className="review_stars">

        {
          Array(review.stars).fill().map((star, index) => (
            <AiFillStar key={index}/>
          ))
        }
        {review.stars}
      </div>
      <div className="review_description">
        Description: {review.description}
      </div>
    </div>
  )
}

export default Review