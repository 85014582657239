import React, { useEffect, useState } from 'react'
import './conversation.css'
import newRequest from '../../utils/newRequest'
import TimeAgo from 'timeago-react'

const Conversation = ({conversation, currentUser}) => {

  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  const unreadCount = conversation.unreadMessages[currentUser._id] || 0;

  const getUser = async() => {

    const contactId = conversation.members.find((member) => member !== currentUser._id) // get user that is not current user
    try {
      const {data} = await newRequest.get(`/user/${contactId}`)
      setUser(data)
    } catch (err) {
      setError(err.response.data)
      console.log("🚀 ~ file: HomeSection.jsx:53 ~ getProjects ~ err.response.data:", err.response.data)
    }
  }

  useEffect(() => {
    getUser()
  }, [currentUser, conversation])
  // console.log("🚀 ~ file: Conversation.jsx:27 ~ Conversation ~ conversation:", conversation)



  return (
    <div className='conversation'>

      <div className='conversation_pp'></div>
      <div className='conversation_content'>
          <h3>{user && user.firstName} {user && user.lastName}</h3>
          <p><TimeAgo datetime={conversation.updatedAt} /></p> 
      </div>
      {conversation.unreadMessages[0] !== 0 ?
      <div className='conversation_unread'>{unreadCount}</div>:<></>
      }
      

    </div>
  )
}

export default Conversation