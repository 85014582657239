import React, { useState } from 'react'
import {Button} from '../componentsIndex'
import './collapsiblefolderexplainer.css'

const CollapsibleFolderExplainer = ({left_title, left_content, right_title, right_content}) => {
  const [folder, setFolder] = useState(true)

  const toggleFolder = () => {
    folder ?  setFolder(false) : setFolder(true)
}

  return (
    <section className='collapse-folder_container'>

      <div onMouseLeave={toggleFolder} className={folder ? "collapse-folder_container_left-folder" : "collapsed-left"}>
          <div className="collapse-folder_container_left_title">
              {left_title}
          </div>
          <div className="collapse-folder_container_left_content">
            <Button
              btnName="Join the Waiting List"
              handleClick={() => {}}
              style={'button_outline'}
              link={'/waiting-list'}
            />
          </div>
      </div>


  
      <div onMouseLeave={toggleFolder} className={folder ? "collapsed-right" : "collapse-folder_container_right-folder"}>
        <div className="collapse-folder_container_right_title">
                {right_title}
            </div>
            <div className="collapse-folder_container_right_content">
                {right_content}
            </div>
      </div>

    </section>
  )
}

export default CollapsibleFolderExplainer