import React, { useState } from 'react'
import './invoicelist.css'
import newAlert from '../../utils/newAlert'
import Invoice from '../Modals/Invoice/Invoice'

const InvoiceList = ({ invoices }) => {

  const [invoiceModal, setInvoiceModal] = useState(false)

  const showInvoice = async() => {
    newAlert("Loading Invoice.", "success")
    // closeModal()
  }


  return (
    <div className="invoice-list">
      {
        invoices.length > 0 ? 
        <table>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <>
                <tr key={invoice._id} onClick={() => setInvoiceModal(true)}>
                  <td>{invoice.invoiceNumber}</td>
                  <td>${invoice.totalAmount}</td>
                  <td>{invoice.issueDate}</td>
                  <td>{invoice.status}</td>
                </tr>
                <Invoice 
                  open={invoiceModal} 
                  closeModal={() => setInvoiceModal(false)}
                  invoiceId={invoice._id}
                /> 
            </>
            ))}
            
          </tbody>
        </table>
        :<p>No Invoices</p>
      }
      

      
    </div>
  )
}

export default InvoiceList