import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import {BsGlobe, BsMoon, BsSun} from 'react-icons/bs'
import {IoMdClose} from 'react-icons/io'
import {MdOutlineAccountCircle} from 'react-icons/md'
import { SearchBar , Button, ToggleTheme} from '../../components/componentsIndex';
import {LoginPage} from '../../pages/LoginPage/LoginPage'
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom'

import './navbar.css'
import { UserContext } from '../../context/UserContext';
import NavOverlay from './NavOverlay';
import axios from 'axios';
import newRequest from '../../utils/newRequest';
import { CgDarkMode } from 'react-icons/cg';
import { BiMoon } from 'react-icons/bi';
import Logo from '../../images/Logo-accent-transparent.png'

const Navbar = ({sticky}) => {
    
    const {loggedIn} = useContext(UserContext)
    const navigate = useNavigate()

    const [marketplace, setMarketplace] = useState(false)
    const toggleMarketplace = () => {
        marketplace ?  setMarketplace(false) : setMarketplace(true)
    }

    const [scrolled, setScrolled] = useState(false)
    const isScrolled = () => {
        window.scrollY > 0 ? setScrolled(true) : setScrolled(false)
    }

    // useEffect(() => {
    //     window.addEventListener("scroll", isScrolled)

    //     return () => {
    //         window.removeEventListener("scroll", isScrolled)
    //     }
    // }, [])

    // const [options, setOptions] = useState(false)
    // const toggleOptions = () => {
    //     options ?  setOptions(false) : setOptions(true)
    // }

    const LightModeToggle = () => {
        const [isLightMode, setIsLightMode] = useState(false);
      
        const toggleLightMode = () => {
          setIsLightMode(prevMode => !prevMode);
        };
      
        useEffect(() => {
          const body = document.body;
          if (isLightMode) {
            body.classList.add('light-mode');
            body.classList.remove('dark-mode');
          } else {
            body.classList.remove('light-mode');
            body.classList.add('dark-mode');

          }
        }, [isLightMode]);
      
        return (
            <button onClick={toggleLightMode}>
                {isLightMode ? <BsMoon />  : <BsSun />}
            </button>
        );
      };



    return (
        <header className={sticky ? 'header-sticky' : ''}>

            <div className={marketplace ? "navbar_overlay" : "navbar_overlay-off"} >
                <IoMdClose className="navbar_overlay-close-icon" onClick={toggleMarketplace}/>
                <NavOverlay toggleMarketplace={toggleMarketplace} />
            </div>

            <div className={scrolled ? "landing-page_navbar" : "landing-page_navbar"}>
                <div className="landing-page_navbar_left">
                    <Link to={'/'}>
                        <a href="#" className='landing-page_navbar_left_logo'>
                            <img
                                className='landing-page_navbar_left_logo_img'
                                src={Logo}
                                alt="reeflex"
                            />
                        </a>
                    </Link>
                    
                    {/* <div className="landing-page_navbar_left_search-bar">
                        <SearchBar search_placeholder={'Browse Projects/ Services'} />
                    </div> */}
                </div>

                <div className="landing-page_navbar_right">

                    {/* <div className="landing-page_navbar_right_cta"> */}
                        <Button
                                btnName="JOIN NOW"
                                handleClick={() => {}}
                                style={'button_outline_white'}
                                link={'/waiting-list'}
                            />
                        {/* not in waitlist */}
                        {/* <Button
                            btnName="Learn"
                            handleClick={() => {}}
                            style={'button_plain'}
                            link={'/learn'}
                        />
                        <Button
                            btnName="Marketplace"
                            handleClick={toggleMarketplace}
                            style={'button_plain'}
                        />
                        {loggedIn == false &&
                            <Button
                                btnName="Join Us"
                                handleClick={() => {}}
                                style={'button_outline'}
                                link={'/register'}
                            />} */}
                    {/* </div> */}

                    {/* <div className="landing-page_navbar_right_language"> */}
                    {/* <LightModeToggle /> */}
                    {/* </div> */}


                        {/* <Link to={loggedIn ? '/dashboard': 'login'}>
                            <MdOutlineAccountCircle 
                                className="landing-page_navbar_right_account_icon"
                            />
                        </Link> */}
                        


                    {/* { options && <div className="landing-page_navbar_right_account_dropdown">
                        {loggedIn && (
                            <Link to={'/dashboard/'}><div>Dashboard</div></Link>

                        )}

                        {loggedIn?.isFreelancer && (
                            <>
                                <div>Projects</div>
                                <div>New Project</div>
                            </>
                        )}
                        <div>Orders</div>
                        <div>Messages</div>

                        {loggedIn && (
                            <div onClick={logOut}>Logout</div>

                        )}

                    </div>} */}

                </div>
                
                

            </div>
            
        </header>

        
    )
}

export default Navbar