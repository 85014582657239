import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../componentsIndex'

const NavOverlay = ({toggleMarketplace}) => {
  return (
    <>
        <div className='navbar-overlay_content'>
      <div className='navbar-overlay_content_top'>
        <div className="navbar-overlay_content_top_category">
          <h2>Graphics & Design</h2>
            <div>Graphics & Design</div>
            <div>Digital Marketing</div>
            <div>Writing & Translation</div>
            <div>Video & Animation</div>
            <div>Music & Audio</div>
            <div>Programming & Tech</div>
            <div>Data</div>
            <div>Business</div>
            <div>Lifestyle</div>
            <div>Photography</div>
            <div>End-to-End Projects</div>
            <div>Sitemap</div>
        </div>
        <div className="navbar-overlay_content_top_category">
          <h2>Digital Marketing</h2>
          <div>Graphics & Design</div>
          <div>Digital Marketing</div>
          <div>Writing & Translation</div>
          <div>Video & Animation</div>
          <div>Music & Audio</div>
          <div>Programming & Tech</div>
          <div>Data</div>
          <div>Business</div>
          <div>Lifestyle</div>
          <div>Photography</div>
          <div>End-to-End Projects</div>
          <div>Sitemap</div>
        </div>
        <div className="navbar-overlay_content_top_category">
          <h2>Writing & Translation</h2>
          <div>Graphics & Design</div>
          <div>Digital Marketing</div>
          <div>Writing & Translation</div>
          <div>Video & Animation</div>
          <div>Music & Audio</div>
          <div>Programming & Tech</div>
          <div>Data</div>
          <div>Business</div>
          <div>Lifestyle</div>
          <div>Photography</div>
          <div>End-to-End Projects</div>
          <div>Sitemap</div>
        </div>
        <div className="navbar-overlay_content_top_category">
          <h2>Video & Animation</h2>
          <div>Graphics & Design</div>
          <div>Digital Marketing</div>
          <div>Writing & Translation</div>
          <div>Video & Animation</div>
          <div>Music & Audio</div>
          <div>Programming & Tech</div>
          <div>Data</div>
          <div>Business</div>
          <div>Lifestyle</div>
          <div>Photography</div>
          <div>End-to-End Projects</div>
          <div>Sitemap</div>
        </div>
        <div className="navbar-overlay_content_top_category">
          <h2>Music & Audio</h2>
          <div>Graphics & Design</div>
          <div>Digital Marketing</div>
          <div>Writing & Translation</div>
          <div>Video & Animation</div>
          <div>Music & Audio</div>
          <div>Programming & Tech</div>
          <div>Data</div>
          <div>Business</div>
          <div>Lifestyle</div>
          <div>Photography</div>
          <div>End-to-End Projects</div>
          <div>Sitemap</div>
        </div>
        <div className="navbar-overlay_content_top_category">
          <h2>Programming & Tech</h2>
          <div>Graphics & Design</div>
          <div>Digital Marketing</div>
          <div>Writing & Translation</div>
          <div>Video & Animation</div>
          <div>Music & Audio</div>
          <div>Programming & Tech</div>
          <div>Data</div>
          <div>Business</div>
          <div>Lifestyle</div>
          <div>Photography</div>
          <div>End-to-End Projects</div>
          <div>Sitemap</div>
        </div>
      
      </div>
      <div className='navbar-overlay_content_bottom'>

        <div className='navbar-overlay_content_bottom_left'>
          
        <div className="navbar_overlay-footer">
            <Button
              btnName="Visit the Marketplace"
              handleClick={toggleMarketplace}
              style={'button_filled'}
              link={'/marketplace'}
            />
        </div>

        </div>
        <div className='navbar-overlay_content_bottom_right'>
        <Button
            btnName="Flex Instant™"
            handleClick={toggleMarketplace}
            style={'button_filled'}
            link={'/flex-instant'}
        />
        </div>
      </div>
    </div>

        
    
    </>
  )
}

export default NavOverlay