import React from 'react'
import './notice.css'
import {AiOutlineMore} from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Notice = ({from, message}) => {
  return (
    <div className='notice'>
        <div className='notice_content'>
            <div className="notice_content_from">
            <Link to={`/profile/${from}`}>{from}</Link>
            </div>
            <div className="notice_content_message">
            {message}
            </div>
            
        </div>
        <div className='notice_details'>
          <AiOutlineMore className='notice_details_icon'/>
        </div>
    </div>
  )
}

export default Notice