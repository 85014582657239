import React, { useContext } from 'react'
import { useState } from 'react'
import './loginpage.css'
import {IoMdClose} from 'react-icons/io'
import { Link, useNavigate, useLocation} from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import newRequest from '../../utils/newRequest'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import useAuth from '../../hooks/useAuth'

// import {
//     LoginSocialGoogle,
//     LoginSocialFacebook,
//     LoginSocialInstagram,
//     LoginSocialTiktok,
//     IResolveParams,
//   } from 'reactjs-social-login';

//   import {
//     FacebookLoginButton,
//     GoogleLoginButton,
//     InstagramLoginButton,
//     TiktokLoginButton,
//   } from 'react-social-login-buttons';

const LoginPage = () => {

    const {setAuth} = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {getUser, getLoggedIn} = useContext(UserContext)

    const [error, setError] = useState(null)

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const loginUser = async(ev) => {
        ev.preventDefault()

        const loginData = {
            email,
            password,
        };
    
        try {
            const {data} = await newRequest.post(
                '/auth/login', 
                loginData
            )
            // localStorage.setItem("currentUser", JSON.stringify(data))
            await getLoggedIn()
            // if logging in for the first time, it should take to the dashboard, else to prev page
            // setAuth( {data} )
            navigate(from, {replace: true})
            getUser(data)
            setEmail('')
            setPassword('')
            const accessToken = data?.cookies.tokes;
            console.log("🚀 ~ file: LoginPage.jsx:61 ~ loginUser ~ accessToken:", accessToken)
            setAuth({ email, accessToken  });
            
        } catch (err) {
            setError(err.message)
            console.log("🚀 ~ file: LoginPage.jsx:42 ~ loginUser ~ err:", err)
            // console.log("🚀 ~ file: LoginPage.jsx:38 ~ loginUser ~ err:", err.response.data)
        }
        
    }


    return (
        <div className='login-page'>
            <Link to={'/'}><IoMdClose className="login-page_close-icon"/></Link>

            <div className="login-page_logo"><Link to={'/'}>reeflex.</Link></div>

            <form className='login-page_form' onSubmit={loginUser}>

                <div className="login-page_form_title">Log in to Reeflex</div>

                <div className='login-page_form_email'>
                    <input 
                        type="email" 
                        placeholder='Email' 
                        value={email} 
                        onChange={ev => setEmail(ev.target.value)} 
                        required/>
                </div>
                <div className='login-page_form_pass'>
                    <input 
                        type="password" 
                        placeholder='Password' 
                        value={password} 
                        onChange={ev => setPassword(ev.target.value)} 
                        required/>    
                </div>

                
                <div className='button login-page_form_submit'>
                    <input type="submit" name='Continue'/>
                </div>

                <div className='login-page_form_register'>
                New to Reeflex? <Link className='login-page_form_register_link' to={'/register'}>Create an account</Link>
                </div>

                {error && <ErrorMessage message={error} />}

            </form>
        </div>
    )
}

export default LoginPage