import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import './checkoutform.css'; // Import CSS file for styling

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

const CheckoutForm = ({ orderId }) => {
  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams(); // Retained for potential future use

  useEffect(() => {
      if (!stripe) return;
  
      const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
      
      if (!clientSecret) return;
  
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `http://localhost:3000/success/${orderId}`,
      },
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };
  
  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit} className="checkout_form">
        <PaymentElement id="payment-element" options={CARD_ELEMENT_OPTIONS} />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="checkout_form-button">
          {
            isLoading ? 
            <div className="spinner" id="spinner"></div> 
            :"Make Payment"
          }
        </button>
        <div className="checkout_form-button_footnote">SSL Secure Payment</div>

        {message && <div id="payment-message" className="payment-message">{message}</div>}
      </form>
    </div>
  );
}


          

export default CheckoutForm;
