import React from 'react'
import './errormessage.css'

const ErrorMessage = ({message}) => {
  return (
    <div className="error-message">
      <p>{message}</p>
    </div>

  )
}

export default ErrorMessage