import React, { useEffect, useState } from 'react'
import './reviews.css'
import { Review } from '../componentsIndex'
import newRequest from '../../utils/newRequest'
import { AiOutlineStar } from 'react-icons/ai'

const Reviews = ({projectId}) => {

    const [reviews, setReviews] = useState([])
    const [error, setError] = useState()


    const [noOfStars, setNoOfStars] = useState()
    const [reviewDescription, setReviewDescription] = useState()




    const submitReview = async(ev) => {
      ev.preventDefault()


    }

    const getReviews = async() => {
      try {
        const {data} = await newRequest.get(`/ratings/rating/${projectId}`)
        setReviews(data)
      } catch (err) {
        setError(err.response.data)
        console.log("🚀 ~ file: Reviews.jsx:17 ~ getReviews ~ err.response.data:", err.response.data)
      }
    }
    
    
    useEffect(() => {
      getReviews()
    }, [])

  return (
    <div className='reviews'>
        
      {
        reviews.map((review, index) => (
          <Review 
            key={review._id}
            review={review}
          />
        ))
      }

       {/*will not be here, will be during the order process  */}
      <div className="leave-review">
        Leave a review

        <form action="" onSubmit={submitReview}>
          <input 
            type="text" 
            placeholder='how did they do?' 
            value={reviewDescription} 
            onChange={ev => setReviewDescription(ev.target.value)} 
          />
          <select name="" id="" onChange={ev => setNoOfStars(ev.target.value)}>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
          <AiOutlineStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar /><AiOutlineStar />
        </form>
        
      </div>
        


    </div>
  )
}

export default Reviews