import React from 'react'
import './category.css'

const Category = ({category}) => {
  return (
    <div className="category category-container">
        <div className="category-header"></div>
        <div className="category-content">
        
          <div className="category-content_title">
            <h3>{category}</h3>
          </div>
          

          <img src="https://lh3.googleusercontent.com/geTFW9hgPMsAiifYUNutYZTXfREGWJ39PJeCRC3DIFgX874pnCY9Y_gKHKQwUOM_HIbE2Wf5KFqgoPiJmr_mtczCLj9ExGlmQ5ZR"/>

        </div>
    </div>
  )
}

export default Category